module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.grandandwild.com","noTrailingSlash":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"969644237577766"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WTXS9VM","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"home","exclude":["/dev-404-page","/404","/404.html","/offline-plugin-app-shell-fallback"],"useClassNames":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#1abc9c","showSpinner":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-JESLZGXMCT"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
